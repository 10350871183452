interface Location {
  location?: any;
  setLocation?: any;
}

interface Store {
  store?: any;
  dispatch?: any;
}

const MainMemo = (loc: Location, sto: Store) => {

  const MemoContext = () => ({
    setLocation: (path: string) => {
      loc.setLocation(path);
    },
    getLocation: () => {
      return loc.location;
    },
    editCardTxt: (text: string) => {
      sto.dispatch({ type: "set", data: { cardTxt: text } })
    },
    getCardTxt: () => {
      return sto.store.cardTxt;
    },
    increment: () => {
      sto.dispatch({ type: 'set', data: { count: sto.store.count + 1 } })
    },
    decrement: () => {
      sto.dispatch({ type: 'set', data: { count: sto.store.count - 1 } })
    }
  });

  return MemoContext;
};

export default MainMemo;
