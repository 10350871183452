// This file sets hidden links so that react-snap can pre-render the routes

import React from "react";

import { Link } from "wouter";

const ReactSnapLinks: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <Link href="/" style={{display: 'none'}} />
      <Link href="/another" style={{display: 'none'}} />
    </React.Fragment>
  );
};

export default ReactSnapLinks;
