const initialState: any = {
  count: 0,
  cardTxt: "This is a media card.",
  cartItems: [],
  searchTxt: "",
  loadOnNav: false,
  hasCameraPermission: null,
};

export default initialState;
