import React from "react";

interface Props {
  isActive: boolean;
  message: string;
}

const PageLoader: React.FunctionComponent<Props> = (props) => {
  return (
    <div
      className={`pageloader is-dark is-bottom-to-top ${
        props.isActive ? "is-active" : ""
      }`}
      style={styles.plbg}
    >
      <span className="title">{props.message}</span>
    </div>
  );
};

export default PageLoader;

const styles = {
  plbg: {
  //  backgroundColor: "orange",
  //  opacity: 0.8,
  },
};
