import React, { useState, useContext, useEffect } from "react";
import logo from "../logo.svg";
import "../App.scss";

import moment from "moment-timezone";

import ReactSnapLinks from "../utils/reactSnapLinks";

import { MainContext, SecondaryContext } from "../store";

import PageLoader from "../components/PageLoader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LoaderState {
  isLoaderActive: boolean;
}

const Home = () => {
  const [state, setState] = useState<LoaderState>({ isLoaderActive: false });

  const { store } = useContext<any | undefined>(MainContext);
  const { getCardTxt, setLocation } = useContext<any | undefined>(
    SecondaryContext
  );

  const [time, setTime] = useState<number | undefined>(undefined);

  useEffect(() => {
    const timer = setInterval(() => {
      console.log("time: ", Date.now());
      setTime(Date.now());
    }, 1000);
    // Specify how to clean up after this effect:
    return () => {
      console.log("interval cleared");
      clearInterval(timer);
    };
  }, []);

  const toggleLoader = () => {
    setState({ ...state, isLoaderActive: true });
    setTimeout(() => {
      setState({ ...state, isLoaderActive: false });
    }, 3000);
  };

  return (
    <div className="App">
      <ReactSnapLinks />
      <PageLoader message="Please Wait..." isActive={state.isLoaderActive} />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
          <br />
          Count: {store.count}
          <br />
          Current Date and Time:{" "}
          {moment(time)
            .tz("America/Chicago")
            .format("MMMM Do YYYY, h:mm:ss a z")}
        </p>
        <a
          className="App-link"
          href="javascript:void(0)"
          target="_self"
          rel="noopener noreferrer"
          onClick={() => setLocation("/another")}
        >
          Go To Another!
        </a>{" "}
        <br />
        <div className="buttons">
          <button className="button is-success" onClick={() => toggleLoader()}>
            Loading Mask
          </button>
        </div>
        {getCardTxt()}
        <div>
          <FontAwesomeIcon icon="check-square" /> Your{" "}
          <FontAwesomeIcon icon="coffee" /> is hot and ready!
        </div>
      </header>
    </div>
  );
};

export default Home;
