import React, { useReducer, useMemo } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";

import uselocation from "wouter/use-location";
import { useLocation } from "wouter";

import Router from "./utils/router";
// import { useHashLocation } from "./utils/location"; //Hash Router
import MainMemo from "./utils/memo";

import {
  MainContext,
  MainReducer,
  MainInitState,
  SecondaryContext,
} from "./store";

library.add(fab, faCheckSquare, faCoffee);

const App: React.FunctionComponent = () => {
  const [store, dispatch] = useReducer(MainReducer, MainInitState);
  const [location, setLocation] = useLocation();

  const MemoContext = useMemo(
    MainMemo({ location, setLocation }, { store, dispatch }),
    [store]
  );

  return (
    <MainContext.Provider value={{ store, dispatch }}>
      <SecondaryContext.Provider value={MemoContext}>
        <Router hook={uselocation} />
      </SecondaryContext.Provider>
    </MainContext.Provider>
  );
};

export default App;
