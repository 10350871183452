import initialState from './MainInitState';
import SetObjHandler from './SetObjHandler';

interface Action {
  type: string;
  data?: object;
}

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "set":
    const setObj = new SetObjHandler('setObj');
    setObj.setObj = action.data;
    // console.log(setObj.getObjKey(0));
    return { ...state, ...setObj.getObj };
    default:
      //alert(action.cardTxt)
      return state;
  }
};

export default reducer;
