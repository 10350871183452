import React, { useContext } from "react";
import logo from "../logo.svg";
import "../App.scss";
import { SecondaryContext } from "../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Nullable<T> = T | undefined | null;

const Another = () => {
  const {
    editCardTxt,
    getCardTxt,
    increment,
    decrement,
    setLocation,
  } = useContext(SecondaryContext);

  const editCardText = () => {
    let cardText: Nullable<string> = prompt("Edit Card Text: ", getCardTxt());

    if (cardText) {
      editCardTxt(cardText);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Another Page</p>
        <a
          className="App-link"
          href="javascript:void(0)"
          target="_self"
          rel="noopener noreferrer"
          onClick={() => setLocation("/")}
        >
          Go Home
        </a>{" "}
        <br />
        <div className="buttons"></div>
        <div className="buttons">
          <button className="button is-success" onClick={() => increment()}>
            Increment
          </button>
          <button className="button is-danger" onClick={() => decrement()}>
            Decrement
          </button>
          <button className="button is-orange" onClick={() => editCardText()}>
            Edit Card Text
          </button>
        </div>
        <div>
          <span>
            <FontAwesomeIcon icon={["fab", "apple"]} />
          </span>
          &nbsp;
          <span>
            <FontAwesomeIcon icon={["fab", "microsoft"]} />
          </span>
          &nbsp;
          <span>
            <FontAwesomeIcon icon={["fab", "google"]} />
          </span>
          &nbsp;
          <span>
            <FontAwesomeIcon icon="check-square" />
          </span>
        </div>
        With Coffee Checked, these companies always know their coffee is hot and
        ready!
      </header>
    </div>
  );
};

export default Another;
