/* function Frozen(constructor: Function) {
  Object.freeze(constructor);
  Object.freeze(constructor.prototype);
} */

interface SetObj {
  name: string;
  obj: any;
  setObj: (Obj: any) => void;
  getObj: () => {};
  getObjKey: (Ind: number) => object;
  getAll: () => {};
}

// @Frozen
class SetObjHandler implements SetObj {
  name: string;
  obj: any;

  constructor(name: string) {
    this.name = name;
    this.obj = {};
  }

  set setObj(Obj: any) {
    Object.keys(Obj).forEach(key =>{
      this.obj[key] = Obj[key];
    })
  }

  get getObj() {
    return this.obj;
  }

  getObjKey(Ind: number) {
    return this.obj[Object.keys(this.obj)[Ind]];
  }

  getAll() {
    return { name: this.name, obj: this.obj };
  }
}

export default SetObjHandler;
