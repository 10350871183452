import React from "react";
import { Router, Route, Switch } from "wouter";

import Home from "../views/Home";
import Another from "../views/Another";

interface Props {
  hook: any
};

const Nav: React.FunctionComponent<Props> = (props) => {
  return (
    <React.Fragment>
      <Router hook={props.hook}>
        <Switch>
          <Route path="/" component={Home} />
          <Route path="/another" component={Another} />
          <Route path="/:rest*">404, not found!</Route>
        </Switch>
      </Router>
    </React.Fragment>
)
};

export default Nav;
